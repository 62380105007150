import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';

import { processArticleContent } from '../../utils/processHTMLContent';
import FullWidthTemplateHeader from '../../components/Article/components/Templates/FullWidthTemplate/components/FullWidthTemplateHeader/FullWidthTemplateHeader';
import Seo from '../../components/Seo';
import ContentDislosure from '../../components/ContentDisclosure/ContentDisclosure';
import DisclosuresESI from '../../components/DisclosuresESI/DisclosuresESI';
import TopSlider from '../../components/TopSlider';
import { createTemplateContext } from '../../utils/helpers';
import { isAdvertiserDisclosureDisabled } from '../../utils/helpers/toggleElements';
import { loadInfogramScript } from '../../components/Infogram';
import { HT_DEFAULT_PAGE_NAME } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IDataTemplate {
  page: Queries.WpPage;
}

const CleanLandingPageWithTopNavTemplate = ({ data, location }: PageProps<IDataTemplate>) => {
  const {
    page: { content = '', title, cardMentionsAll, nonAffiliateAll, databaseId },
  } = data;
  const { articleContent } = processArticleContent(
    content || '',
    '/TE/',
    'Clean Landing Page With Top Nav',
  );
  const context = createTemplateContext(data);
  const isDisabledAdvertiserDisclosure = isAdvertiserDisclosureDisabled(data);
  useEffect(() => {
    if (document.querySelectorAll('.article .infogram-embed').length > 0) {
      loadInfogramScript();
    }
  }, []);
  return (
    <HTPageWrapper
      category="Landing Page"
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <div className="template_landerWIthNav">
        <TopSlider context={context} />
        <div className="credit-card-template-header">
          <div className="container">
            <FullWidthTemplateHeader
              title={title || ''}
              featuredImage={null}
              author={null}
              breadCrumbs={null}
              tooltip={null}
            />
          </div>
        </div>
        <div className="container">
          {!isDisabledAdvertiserDisclosure && <ContentDislosure />}
          <div className="article">
            {articleContent}
            <DisclosuresESI
              parentId={databaseId}
              cardMentions={cardMentionsAll}
              nonAffiliate={nonAffiliateAll}
            />
          </div>
        </div>
      </div>
    </HTPageWrapper>
  );
};

export default CleanLandingPageWithTopNavTemplate;

export const Head = ({ data }: PageProps<IDataTemplate>) => {
  const { title, seo } = data.page;
  return (
    <>
      <Seo title={title || ''} wpSeo={seo} />
    </>
  );
};

export const pageQuery = graphql`
  query CleanLandingPageWithTopNavTemplate($id: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageData
    }
  }
`;
